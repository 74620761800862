@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  height: 100%;    
  background-image: url('../bggreen.png');
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
}
.MuiInputBase-root.Mui-disabled {
  color: #333333 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiToolbar-regular {
  min-height: 50px !important
}
#loader {
  opacity: 0;
  transition: z-index 1s step-end, opacity .8s ease-in-out;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

#root:empty+#loader {
  opacity: 1;
  z-index: 100;
}
html,body {
    height:100%;
}
.maincontainer {
    display: flex;
    flex-grow: 1;
}

span.MuiTableSortLabel-root:hover, svg.MuiTableSortLabel-icon:hover{
    color: #315bc1 !important;
    cursor: pointer;
}
th.MuiTableCell-root, td.MuiTableCell-root {
    border: 1px solid #f1f1f1;
}
.MuiTableCell-head {
    font-size: 80% !important;
    background-color: #f5ece2;
    border: 1px solid #e2e2e2 !important;
}
.MuiFilledInput-input{
    padding: 6px 12px !important;
}
.MuiInputBase-input {
    background-color: #fff !important;
    width: 100% !important;
    border: 1px solid #f1f1f1;
    padding: 2px 5px;
}
.MuiInputBase-marginDense .MuiSelect-select {
    padding: 1px 5px !important;
}
.MuiTablePagination-input .MuiSelect-select {
    padding: 3px 6px 0 6px !important
}
.MuiOutlinedInput-input, .MuiSelect-selectMenu {
    padding: 10px !important;
}
.MuiOutlinedInput-inputMarginDense {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.MuiButton-contained {
    color: '#6495ed',
}
.fa-small {
    font-size: 14px !important;
    margin: 0 3px;
}
.inputRoot {
    color: 'inherit'
}
.inputInput
{
    padding: 1px;
    width: 210px;
    border-bottom: '1px solid #cccccc';
    margin-left: 2px;
    color: 'inherit';
}
.inputInput:hover {
    border-bottom: '1px solid #333333'
}
.drawerPaper {
    width: 220px;
    background-color: '#f2fafd';
    background-image: url('../sidebar-bg.jpg');
    background-size: 'cover';
    background-repeat: repeat;
  }
 
.select_edit  .MuiSelect-root {
    padding: 10px !important;
}
.listRowNum {
    color: #a09f9f  !important;
    max-width: 45px !important;
    padding: 0 8px !important;
}
.tabIcon {
    position: relative;

}
.MuiButton-label {
    text-transform: initial !important;
}
.MuiButton-label > span, .tabIcon > span {
    display: inline-block;
    vertical-align: middle;    
}
.MuiButton-label > i, .tabIcon > i {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;    
    padding-right: 5px;
}
ul.react-tabs__tab-list{
    background-color: #dfefd0;
}
.MuiOutlinedInput-input {
    padding: 5px;
}
.MuiDialog-paperFullScreen {
    width: 90% !important;
    height: 90% !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
    color: #0a66b7 !important;
}
.fa, .fas, .material-icons {
    font-size: 16px !important;
}
table {
    border-collapse: collapse;
    width: 100%;
    font-size: 95%;
}
td.tdLabel {
    border-bottom: 1px solid #cccccc;
    padding: 4px 15px 4px 15px;   
    width: 90px;
    white-space: nowrap;
    background-color: #fbfbf9;
    border-right: 1px solid #efefec; 
}
td.tdContent {
    border-bottom: 1px solid #cccccc;
    padding: 4px 5px;    
}
td.tdEdit {
    border-bottom: 1px solid #cccccc;
    padding: 4px 5px;   
    height: 44px;   
}
td.tableContainer {
    padding: 0;
    border: 1px solid #cccccc;
    vertical-align: top;
}
tfoot td {
    font-size: 100% !important;
    font-weight: bold !important;
    
}
.reportTitleGreen {
    color: green !important;
}
.reportTitleRed {
    color: red !important;
}
.MuiFormControl-marginDense {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.tdEdit input {
    padding: 3px 5px !important;
}
.MuiGrid-root .MuiInputBase-root {
    padding: 15px 0 15px 15px !important;
}
.Mui-disabled {
    background-color: #e8e8e8 !important;
}